<!-- 申请仅退款-退款成功 -->
<template>
  <div class="refundresult">
    <div class="result">
      <p class="pms"><span class="icon"><svg><use xlink:href="#iconwancheng"></use></svg></span>退款成功</p>
      <p class="other"><span class="x">*</span>退款金额：<span class="q">{{productList?productList.RefundApplyModel.RefundMoney:''}}元</span></p>
      <p class="other"><span class="x">*</span>钱款去向：原路返回（{{productList?productList.PaymentType:''}}）</p>
      <p class="other">退款到账时间：{{productList?productList.RefundApplyModel.RefundDate:'' | rttime}}</p>
    </div>
    <p class="p">如有疑问，请联系<a href="javascript:;" @click='lianxikefu'>在线客服>></a></p>
  </div>
</template>
<script>
export default {
  name: 'refundresult',
  props: {
    productList: Object
  },
  methods:{
    lianxikefu(){
      // 联系客服
      _MEIQIA('showPanel');
    }
  },
  mounted(){
  },
  filters:{
    rttime(v){
      return v.split('T')[0]+' '+v.split('T')[1]
    }
  }
}
</script>
<style  lang="scss" scoped>
.refundresult {
  .result {
    width: 740px;height: 190px;background: #fafafa;border-radius: 10px;margin-top:40px;
    .pms {
      height: 80px;line-height: 80px;font-size: 20px;color:#333;font-weight: bold;padding-left:40px;
      .icon {
         width: 30px;height: 30px;display: inline-block;background: #4ed45b;line-height: 35px;margin-right: 15px;text-align: center;border-radius: 50%;
        svg {
          width: 20px;height: 20px;fill: #fff;margin-right: 0px;vertical-align: -3px;
        }
      }
    }
    .other {
      padding-left:85px;font-size: 14px;margin-bottom:10px;
      span {
        color:#f96464;
      }
      .x {
        margin-left:-5px;
      }
    }
  }
  .p {
    padding-left:80px;height: 55px;line-height: 55px;
    a {
      color:#2c80db;
      &:hover {
        opacity:0.8;
      }
    }
  }
}
</style>
