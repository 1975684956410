<!-- 申请退款-商品列表 -->
<template>
  <div class="refundlist">
    <h6 class="h6">订单详情</h6>
    <div class="item">
      <div class="list" v-if='productList' v-for='item,index in productList.jobList' :key='index'>
        <div class="img" @click='lingdetail(item)'>
          <img :src="item.ImageUrl|imgsrc(that)">
        </div>
        <div class="info">
          <h6 class="h" :title="item.JobSubsTypesName"  @click='lingdetail(item)'>{{item.JobSubsTypesName}}</h6>
          <p>数量：{{item.DzproductnumberName}}</p>
          <p>规格：{{item.DzmaterialName}}</p>
          <p>纸巾尺寸：{{item.DzspecificationName}}</p>
          <p>单价：&yen;{{item.Price}}</p>
          <p>x{{item.JobNum}}</p>
        </div>
      </div>
    </div>
    <div class="iteminfo">
      <div class="lt">
        联系电话：
      </div>
      <div class="rt">
        {{productList?productList.ContactPhone:''}}
      </div>
    </div>
    <div class="iteminfo">
      <div class="lt">
        订单编号：
      </div>
      <div class="rt">
        {{productList?productList.OrderName:''}}
      </div>
    </div>
<!--     <div class="iteminfo">
      <div class="lt">
        单价：
      </div>
      <div class="rt">
        <span class="pr">&yen;1300.00</span> <span class="nm">x2</span> <span class="tx">(数量)</span>
      </div>
    </div> -->
    <div class="iteminfo">
      <div class="lt">
        订单总额：
      </div>
      <div class="rt">
        <span class="pr">&yen;{{productList?productList.PayedAmount:''}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'refundlist',
  props: {
    productList: Object
  },
  data(){
    return {
      that:this
    }
  },
  methods:{
    lingdetail(item){
      // console.log(item)
      // return;
      var _idcx = item.DynamicId?item.DynamicId:''
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id::res.data.Data.producttypeid,idcx:'',detailType:1}})
        this._open({path:'/predetail',query:{idcx:_idcx,id:item.JobSubsTypesId+'.html',}})
      }})
    },
  },
  mounted(){
  },
  filters:{
    imgsrc(v,that){
      return that._src +v
    }
  }
}
</script>
<style  lang="scss" scoped>
.refundlist {
  .h6 {
    font-size: 22px;font-weight: normal;color:#5e5e5e;height: 60px;line-height: 60px;
  }
  .item {
    border-top:1px solid #e9e8e8;border-bottom:1px solid #e9e8e8;padding:20px 0px;
    .list {
      display: flex;justify-content: center;align-items: flex-start;padding:0 10px;margin-bottom:15px;
      .img {
        border:1px solid #f2f2f2;width: 62px;height: 62px;cursor: pointer;
        img {
          display: block;width: 62px;height: 62px;
        }
      }
      .info {
        margin-left:10px;width: 220px;
        .h {
          overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;font-weight: normal;font-size: 14px;margin-bottom:5px;cursor: pointer;
        }
        p {
          color:#b7b8b7;font-size: 14px;
        }
      }
    }
  }
  .iteminfo {
    margin:10px 10px;margin-bottom:10px;zoom:1;
    .lt {
      float:left;width: 75px;color:#9fa19f;font-size: 14px;text-align: right;padding-right: 5px;
    }
    .rt {
      float:right;width: calc(100% - 80px);font-size: 14px;
      .pr {
        color:#ff4e0b;
      }
      .nm {
        margin:0 5px;
      }
      .tx {
        color:#a5a7a5;
      }
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
}
</style>
