<!-- 退款表格 -->
<template>
  <div class="refundtable">
    <div class="group-table">
      <div class="lt">
        退款商品：
      </div>
      <div class="rt rt1">
        <div class="rtitem" v-if='productList' v-for='item,index in productList.jobList' :key='index'>
          <div class="img">
            <img :src="item.ImageUrl|imgsrc(that)">
          </div>
          <div class="info">
            <h6 :title="item.JobSubsTypesName">{{item.JobSubsTypesName}}</h6>
            <p>数量：{{item.DzproductnumberName}}</p>
            <p>规格：{{item.DzmaterialName}}</p>
            <p>纸巾尺寸：{{item.DzspecificationName}}</p>
            <p>x{{item.JobNum}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="group-table">
      <div class="lt">
        服务类型：
        <span>*</span>
      </div>
      <div class="rt">
        <p class="msg">仅退款</p>
      </div>
    </div>
    <div class="group-table">
      <div class="lt">
        货物状态：
        <span>*</span>
      </div>
      <div class="rt">
        <div class="radioBox">
          <el-radio v-model="radio" label="1" >未收到货</el-radio>
          <el-radio v-model="radio" label="2" >已收到货</el-radio>
        </div>
        <span class="error" v-if='error.radio'>{{error.radio}}</span>
      </div>
    </div>
    <div class="group-table">
      <div class="lt">
        退款原因：
        <span>*</span>
      </div>
      <div class="rt">
        <div class="selectBox">
          <el-select v-model="value" placeholder="请选择" >
            <el-option
              v-for="item,index in options"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <span class="error error1" v-if='error.value'>{{error.value}}</span>
      </div>
    </div>
    <div class="group-table">
      <div class="lt">
        退款金额：
        <span>*</span>
      </div>
      <div class="rt">
        <div class="rePrice">
          <span>&yen;</span>
          <input type="number" disabled="disabled" :value="productList?productList.RefundAmount:0">
          <p>最多&yen;{{productList?productList.RefundAmount:0}}</p>
          <!-- <p>最多&yen;500.00,含发货邮费&yen;30.00</p> -->
        </div>
      </div>
    </div>
    <div class="group-table">
      <div class="lt">
        退款说明：
      </div>
      <div class="rt">
        <div class="textareaBox">
          <textarea placeholder="退款说明" v-model='txt'></textarea>
        </div>
      </div>
    </div>
    <div class="group-table">
      <div class="lt">
        上传图片：
      </div>
      <div class="rt">
        <div class="uploadimg">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            :multiple='true'
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :before-upload='upfn'
            :http-request='selfupload'
            accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF,"
            >
            <span class="iconss"><svg><use xlink:href="#iconxiangji"></use></svg></span>
            <p>电脑传图</p>
          </el-upload>
          <div class="imgnum">共<span>{{uploadImg}}</span>张，还能上传<span>{{maxUploadImg-uploadImg}}</span>张</div>
          <div class="imginfo">请提供实物图片(包含条形码)，以便您的退款或退货申请顺利通过审核</div>
        </div>
      </div>
    </div>
    <div class="group-table">
      <div class="lt">
      </div>
      <div class="rt">
        <div class="uploadMenu">
          <button @click='postProduct' :disabled="btmpas">提交</button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'refundtable',
  data(){
    return {
      btmpas:false,//禁用按钮
      radio:'',
      txt:'',
      options: [{
        value: '退运费（部分差价）',
        label: '退运费（部分差价）'
      }, {
        value: '快递一直未送到',
        label: '快递一直未送到'
      }, {
        value: '快递破损已拒收',
        label: '快递破损已拒收'
      }, {
        value: '协商一致退款',
        label: '协商一致退款'
      }, {
        value: '其他',
        label: '其他'
      }],
      value:'',
      that:this,
      uploadImg:0,//当前上传图片数量
      maxUploadImg:3,//上传图片上限
      dialogImageUrl: '',//预览图片地址
      dialogVisible: false,//显示隐藏预览弹窗
      rmkObj:{
        uploaddata:[],
      },//提交退款对象
      error:{
        radio:'',
        value:'',
      }
    }
  },
  props: {
    productList: Object
  },
  methods:{
    postProduct(){
      // 提交退款
      this.error.radio=''
      this.error.value=''
      var _flag = true;
      if(!this.radio){
        _flag = false
        this.error.radio='请选择货物状态'
      }
      if(!this.value){
        _flag = false
        this.error.value='请选退款原因'
      }
      if(_flag){
        var _parms = {
          // CustomerId:this.$store.state.user&&this.$store.state.user.CustomerID?this.$store.state.user.CustomerID:'',
          token:this._token({method:'get'}),
          OrderName:this._spf(this.$route.query.OrderName),
          RefundType:1,
          RefundReason:this.value,
          RefundMoney:this.productList.RefundAmount,
          RefundDesc:this.txt,
          GoodsState:this.radio,
          ProductId:this._spf(this.$route.query.ProductId),
        }
        for(let i in this.rmkObj.uploaddata){
          _parms['file'+i] = this.rmkObj.uploaddata[i]
        }
        // 保存退款信息
        this.btmpas = true;//请求接口禁用完成前调用一次
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/SaveRefundApply',this.keys(_parms))
            .then(res => {
              if(res.data.Success){
                this.btmpas = false//请求接口禁用完成前调用一次
                this.$emit('exportPost')
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    },
    handlePictureCardPreview(file) {
      // 预览上传图片
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      // 删除上传图片
      this.uploadImg = fileList.length
      this.rmkObj.uploaddata = [];
      for(let i in fileList){
        this.rmkObj.uploaddata.push(fileList[i].raw)
      }
      this.$emit('expupload',this.rmkObj)
    },
    upfn(file){
      // 上传前计数
      this.uploadImg++
      if(this.uploadImg>this.maxUploadImg){
        this.uploadImg=this.maxUploadImg
        this.$MessageBox.alert('最多上传3张图片',{type:'error'});
        return false
      }
      // return;
    },
    selfupload(file){
      // 自定义上传
      this.rmkObj.uploaddata.push(file.file)
      this.$emit('expupload',this.rmkObj)
    },
  },
  mounted(){
  },
  filters:{
    imgsrc(v,that){
      return that._src + v
    }
  }
}
</script>
<style  lang="scss" scoped>
.refundtable {
  .group-table {
    display: block;zoom:1;margin-bottom:40px;
    .lt {
      float:left;width: 80px;color:#0f0f0f;font-size: 14px;position: relative;
      span {
        color:#ff4e0b;position: absolute;left:-10px;top:3px;
      }
    }
    .rt {
      float:right;width: 660px;display: flex;justify-content: left;align-items: center;position: relative;
      .img {
        width: 84px;height: 84px;border:1px solid #f4f4f4;
        img {
          display: block;width: 84px;height: 84px;
        }
      }
      .info {
        margin-left:20px;
        h6 {
          font-size: 14px;color:#0f0f0f;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;
        }
        p {
          font-size: 12px;color:#9f9f9f;
        }
      }
      .msg {
        font-size: 14px;color:#0f0f0f;
      }
      .radioBox {
        margin-top:0px;
      }
      .selectBox {
        margin-top:-9px;
      }
      .rePrice {
        position: relative;margin-top:-9px;
        span {
          position: absolute;font-size: 16px;color:#ff4e0b;font-size: 16px;top:8px;left: 12px;
        }
        input {
          border:1px solid #DCDFE6;width: 191px;height: 38px;border-radius: 3px;color:#ff4e0b;padding-left:24px;font-size: 16px;background: none;
        }
        p {
          color:#a39da1;margin-top:10px;font-size: 12px;
        }
      }
      .textareaBox {
        margin-top:-8px;
        textarea {
          border:1px solid #DCDFE6;width: 440px;height: 125px;padding:10px;font-size: 14px;border-radius: 5px;
          &::placeholder {
            color:#ccc;font-size: 14px;
          }
        }
      }
      .uploadImg {
        width: 460px;
      }
      .uploadMenu {
        button {
          width: 200px;height: 40px;border-radius: 5px;background: #f04565;color:#fff;font-size: 14px;font-weight: bold;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .uploadimg {
        /deep/.el-upload {
          width: 50px;height: 50px;line-height: 8px;
          p {
            font-size: 12px;transform: scale(0.8);margin-top:3px;
          }
        }
        /deep/.el-upload-list__item {width: 50px;height: 50px;line-height: 50px;margin-bottom:0px;}
        /deep/.iconss {
          vertical-align: -4px;
          svg {
            width: 30px;height: 30px;fill: #ccc;
          }
        }
        .imgnum {
          font-size: 12px;color:#999999;margin-top:5px;
          span {
            color:#e4393c;font-size: 12px;margin:0 2px;
          }
        }
        .imginfo {
          font-size: 14px;color:#797979;margin-top:5px;
        }
      }
      .error {
        position: absolute;top:22px;font-size: 12px;color:red;
      }
      .error1 {
        top:33px;
      }
    }
    .rt1 {
      display: block;
      .rtitem {
        display: flex;margin-bottom:10px;
        align-items: flex-start;
      }
    }
    &:after {
      content: "";display: block;clear:both;
    }
  }
}
</style>
