<!-- 退款审核 -->
<template>
  <div class="refundaudit">
    <div class="step" v-if='audittype==0'>
      <svg class='svg'><use xlink:href="#iconyixiadan1"></use></svg>您的退款申请已提交，请耐心等待客服人员处理。
    </div>
    <div class="step step1 " v-if='audittype==1'>
      <p class="pm"><span class="icon"><svg><use xlink:href="#iconwancheng"></use></svg></span>您的退款申请已通过。</p>
      <p class="pm1">所申请的退款金额将在<span>5个工作日内</span>退回原支付账户。</p>
    </div>
    <div class="step step1 step2" v-if='audittype==2'>
      <p class="pm"><span class="icon"><svg><use xlink:href="#iconshanchu"></use></svg></span>抱歉，您的退款申请审核未通过。</p>
      <p class="pm1">审核未通过的原因：<span>{{productList&&productList.RefundApplyModel&&productList.RefundApplyModel.AuditDesc?productList.RefundApplyModel.AuditDesc:''}}</span>（此处原因为客服后台拒绝原因）</p>
    </div>
    <p class="p">如有疑问，请联系<a href="javascript:;" @click='lianxikefu'>在线客服>></a></p>
  </div>
</template>
<script>
export default {
  name: 'refundaudit',
  data(){
    return {
    }
  },
  props: {
    audittype: Number,//审核步骤
    productList: Object,//审核数据
  },
  methods:{
    lianxikefu(){
      // 联系客服
      _MEIQIA('showPanel');
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.refundaudit {
  .step {
    width:690px;padding-bottom:20px;border-radius: 10px;background: #fafafa;font-size: 20px;color:#333;padding-left:50px;line-height: 100px;font-weight: bold;margin-top:40px;
    svg {
      width: 35px;height: 35px;vertical-align: -11px;margin-right: 10px;
    }
    .svg {
      fill: #3f86f2;
    }
  }
  .step1 {
    .pm {
      line-height: 70px;font-size: 20px;
    }
    .pm1 {
      line-height: 20px;font-weight: normal;color:#999999;font-size: 14px;padding-left:46px;
      span {
        color:#2c80db;
      }
    }
    .icon {
       width: 30px;height: 30px;display: inline-block;background: #4ed45b;line-height: 35px;margin-right: 15px;text-align: center;border-radius: 50%;
      svg {
        width: 20px;height: 20px;fill: #fff;margin-right: 0px;vertical-align: -3px;
      }
    }
  }
  .step2 {
    .pm1 {
      span {
        color:#f96469;
      }
    }
    .icon {
      background: #f96464;
      svg {
        width: 15px;height: 15px;margin-right: 0px;vertical-align: 0px;
      }
    }
  }
  .p {
    padding-left:80px;height: 55px;line-height: 55px;
    a {
      color:#2c80db;
      &:hover {
        opacity:0.8;
      }
    }
  }
}
</style>
