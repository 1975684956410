<!-- 退款步骤 -->
<template>
  <div class="refundstep">
    <div class="step" :class='{choice:steps==0}'>
      <span class="sp">1</span>买家申请仅退款
      <span class="formR"></span>
    </div>
    <div class="step" :class='{choice:steps==1}'>
      <span class="sp">2</span>客服审核
      <span class="formL"></span>
      <span class="formR"></span>
    </div>
    <div class="step" :class='{choice:steps==2}'>
      <span class="sp">3</span>退款完毕
      <span class="formL"></span>
      <!-- <span class="formR"></span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'refundstep',
  props: {
    steps: {
      default:0,
      type:Number
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.refundstep {
  width: 1200px;margin:0 auto;display:flex;justify-content: center;
  .step {
    background: #cccccc;color:#fff;font-size: 16px;font-weight: bold;width: 380px;margin-right: 30px;height: 37px;text-align: center;line-height: 37px;position: relative;
    .sp {
      width: 20px;height: 20px;color:#ccc;background: #fff;display: inline-block;line-height: 20px;border-radius: 50%;font-size: 14px;margin-right: 10px;
    }
    .formR {
      position: absolute;right: -23px;top:0px;height: 37px;width: 57px;overflow: hidden;
      &:after,&:before {
        content: '';display: block;position: absolute;width: 35px;height: 40px;background: red;right:10px;top:-2px;background: #ccc;
      }
      &:after {
        transform: rotate(50deg);
      }
      &:before {
        transform: rotate(-50deg);
      }
    }
    .formL {
      position: absolute;left: -30px;top:0px;height: 37px;width: 57px;overflow: hidden;
      &:after,&:before {
        content: '';display: block;position: absolute;width: 45px;height: 25px;background: red;right:0px;background: #ccc;
      }
      &:after {
        transform: rotate(50deg);top:-3px;
      }
      &:before {
        transform: rotate(-50deg);top:15px;
      }
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .choice {
    background: #f44e5d;
    .sp {
      color:#f44e5d;
    }
    .formR {
      &:after,&:before {
        background: #f44e5d;
      }
    }
    .formL {
      &:after,&:before {
        background: #f44e5d;
      }
    }
  }
}
</style>
