<!-- 申请仅退款 -->
<template>
  <div class="applyaftercause">
    <!-- 面包屑 -->
    <crumbs class='crumbs'  :location='location' @exportCls='exportCls'></crumbs>
    <refundstep class='refundstep' :steps='refundSteps'></refundstep>
    <div class="center">
      <refundtable :productList='productList' class='refundtable' v-if='refundSteps==0' @exportPost='exportPost'></refundtable>
      <refundaudit :productList='productList' class='refundaudit' v-if='refundSteps==1' :audittype='AuditState'></refundaudit>
      <refundresult :productList='productList' class='refundresult' v-if='refundSteps==2' ></refundresult>
      <refundlist :productList='productList' class='refundlist'></refundlist>
    </div>
  </div>
</template>
<script>
import crumbs from '@/components/crumbs/crumbs.vue';//面包屑导航
import refundstep from '@/components/refundstep/refundstep.vue';//申请仅退款步骤
import refundtable from '@/components/refundtable/refundtable.vue';//申请仅退款-第一步表格
import refundlist from '@/components/refundlist/refundlist.vue';//申请仅退款-第一步表格
import refundaudit from '@/components/refundaudit/refundaudit.vue';//申请仅退款-第二步审核
import refundresult from '@/components/refundresult/refundresult.vue';//申请仅退款-第三步退款成功
export default {
  name: 'applyaftercause',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return  {
      htmltitle:'退款原因-微微定',
      location:[{Name:'首页'},{Name:'我的订单'},{Name:'申请售后'}],//面包屑
      refundSteps:null,//退款步骤状态
      productList:{},//退款商品数据
      AuditState:null,//审核状态
    }
  },
  components: {
    crumbs,
    refundstep,
    refundtable,
    refundlist,
    refundaudit,
    refundresult
  },
  props: {
    msg: String
  },
  methods:{
    exportCls(v){
      // 面包屑点击
      // console.log(v)
      if(v.Name=='首页'){
        this._router({then:()=>{
          this.$router.push('/index.html')
        }})
      }
      if(v.Name=='我的订单'){
        this._router({then:()=>{
          this.$router.push({path: "/usercenterorder.html"})
          // this._open({path: "/usercenterorder"})
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPaht+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    },
    resultget(){
      this._axios({then:()=>{
        // 申请售后页请求
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/RefundApplyInitPage?OrderName='+this._spf(this.$route.query.OrderName)+'&ProductId='+this._spf(this.$route.query.ProductId),this.keys({token:this._token({method:'get'})}))
        .then(res => {
          if(res.data.Success){
            this.productList = res.data.Data
            if(!this.productList.RefundApplyModel){
              this.refundSteps=0
            }else if(this.productList.RefundApplyModel.RefundSate!==2){
              this.refundSteps=1
              this.AuditState = this.productList.RefundApplyModel.AuditState
            }else if(this.productList.RefundApplyModel.RefundSate===2){
              this.refundSteps=2
            }
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    exportPost(){
      // 提交退款成功
      this.resultget()
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.resultget()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.applyaftercause {
  background: #fff;padding-bottom:100px;
  .crumbs {
    padding:10px 0;
  }
  .center {
    border:1px solid #dfdfdf;width: 1198px;margin:0 auto;zoom:1;
    .refundtable {
      width: 740px;padding:20px 50px;float:left;border-right:1px solid #e2e2e2;
    }
    .refundaudit {
      width: 740px;padding:20px 50px;float:left;border-right:1px solid #e2e2e2;min-height: 890px;
    }
    .refundresult {
      width: 740px;padding:20px 50px;float:left;border-right:1px solid #e2e2e2;min-height: 890px;
    }
    .refundlist {
      float:right;width: 307px;padding:0 25px;
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
  .refundstep {
    margin-bottom:10px;
  }
}
</style>
